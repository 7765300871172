import React from "react";
import { Switch } from "@headlessui/react";
import { Controller } from "react-hook-form";
import classNames from "lib/classNames";
import useField from "lib/useField";
import { CheckIcon } from "@heroicons/react/outline";

export default function Checkbox({ control, errors, field }) {
  const { name, disabled, required, requiredMessage } = useField(field);
  const hasError = errors[name] || false;

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required && requiredMessage,
        disabled,
      }}
      render={({ field: { value, onChange } }) => (
        <Switch.Group>
          <div className="flex items-center gap-4">
            <Switch
              checked={value}
              onChange={onChange}
              className={classNames(
                "relative inline-flex p-1 transition-colors border",
                value
                  ? "border-secondary-dark bg-secondary-dark"
                  : "bg-transparent border-secondary border-opacity-50"
              )}
            >
              <CheckIcon
                className={classNames(
                  "h-4 w-4 transition-colors",
                  value ? "text-primary" : "text-transparent"
                )}
              />
            </Switch>

            <Switch.Label className="cursor-pointer">
              {field["#title"]}
              {required && <span className="text-primary"> *</span>}
            </Switch.Label>
          </div>

          {hasError && (
            <p className="text-sm text-red-500 mt-2 font-semibold">
              {errors[name].message}
            </p>
          )}
        </Switch.Group>
      )}
    />
  );
}
