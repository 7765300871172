import React from "react";
import Image from "next/image";

export default function ParagraphImage({ data }) {
  return (
    <figure className="relative">
      <Image
        src={data.field_image.image_style_uri.medium_16by10}
        width={974}
        height={550}
        layout="responsive"
        objectFit="cover"
        alt={data.field_image.resourceIdObjMeta.alt}
      />
      {data.field_image.resourceIdObjMeta.title && (
        <figcaption className="absolute bottom-0 left-0 px-4 py-2 bg-primary text-primary-darker">
          {data.field_image.resourceIdObjMeta.title}
        </figcaption>
      )}
    </figure>
  );
}
