import React from "react";
import Breadcrumb from "components/Breadcrumb";
import { TranslateIcon } from "@heroicons/react/outline";
import useTranslation from "next-translate/useTranslation";

export default function Header({
  crumbs,
  title,
  subtitle,
  rightComponent,
  notTranslated,
}) {
  const { t } = useTranslation("common");

  return (
    <section className="relative pt-6 pb-20 bg-secondary-dark text-white">
      <div className="container mx-auto px-4">
        <Breadcrumb crumbs={crumbs} className="mb-16" />
        <div className="flex flex-col lg:flex-row items-center">
          <h1 className="flex-1 text-4xl lg:text-6xl font-serif">{title}</h1>
          {rightComponent && (
            <div className="mb-4 lg:mb-0">{rightComponent}</div>
          )}
        </div>

        {subtitle && (
          <div className="flex justify-center lg:justify-start items-center mt-8">
            {typeof subtitle === "string" ? (
              <>
                <span
                  className="inline-block h-2.5 w-2.5 mr-4 border-2 border-primary rounded-full"
                  aria-hidden="true"
                />
                <p>{subtitle}</p>
              </>
            ) : (
              subtitle
            )}
          </div>
        )}

        {notTranslated && (
          <div className="flex justify-center lg:justify-start items-center gap-4 mt-8">
            <TranslateIcon className="inline-block h-4 w-4 text-primary" />
            <p>{t("utils.not_translated")}</p>
          </div>
        )}
      </div>
    </section>
  );
}
