import React from "react";
import Button from "components/Button";
import classNames from "lib/classNames";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import Body from "components/Body";

export default function ParagraphCard({ data }) {
  const router = useRouter();

  const hasImage = data.field_image || false;

  const clickHandler = (event) => {
    if (data.field_link) {
      event.preventDefault();
      router.push(data.field_link.url);
    }
  };

  return (
    <div
      className={classNames(
        "bg-secondary-light",
        !hasImage && "border-t-2 border-primary"
      )}
      onClick={clickHandler}
    >
      {hasImage && (
        <figure>
          <Image
            src={data.field_image.image_style_uri.small_16by10}
            alt={data.field_image.resourceIdObjMeta.alt}
            width="462"
            height="260"
            layout="responsive"
            objectFit="cover"
          />
          {data.field_image.resourceIdObjMeta.title && (
            <figcaption className="sr-only">
              {data.field_image.resourceIdObjMeta.title}
            </figcaption>
          )}
        </figure>
      )}
      <div className="p-12">
        <div className="text-lg mb-8">{data.field_title}</div>
        <div className={classNames(data.field_link && "mb-8")}>
          <Body value={data.field_text.value} />
        </div>
        {data.field_link && <CardLink link={data.field_link} />}
      </div>
    </div>
  );
}

function CardLink({ link }) {
  const fromUri = link.uri.split(":").pop();
  return (
    <Link href={link.url !== "" ? link.url : fromUri} prefetch={false} passHref>
      <Button as="a" size="small">
        {link.title}
      </Button>
    </Link>
  );
}
