import React from "react";
import { Disclosure, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import classNames from "lib/classNames";

export default function ParagraphCollapse({ data, loopIndex }) {
  return (
    <Disclosure
      as="div"
      defaultOpen={loopIndex === 0}
      className="border-b border-gray-300 px-8 py-6"
    >
      {({ open }) => (
        <>
          <Disclosure.Button className="flex items-center py-4 w-full mx-auto text-lg text-left">
            <span className="flex-1">{data.field_title}</span>
            <span
              className={classNames(
                "border border-primary p-2 rounded-full transition-colors",
                !open && "bg-primary"
              )}
            >
              <ChevronDownIcon
                className={classNames(
                  "h-4 w-4 text-primary-darker transition-all",
                  open && "rotate-180"
                )}
              />
              <span className="sr-only">{open ? "Replier" : "Déplier"}</span>
            </span>
          </Disclosure.Button>
          {open && (
            <Transition
              show={open}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel
                className="py-4 prose max-w-none"
                dangerouslySetInnerHTML={{ __html: data.field_text.value }}
              />
            </Transition>
          )}
        </>
      )}
    </Disclosure>
  );
}
