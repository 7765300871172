import React from "react";
import formatFileSize from "lib/formatFileSize";
import Link from "next/link";
import { DownloadIcon, EyeIcon } from "@heroicons/react/outline";
import useTranslation from "next-translate/useTranslation";

export default function ParagraphFiles({ data }) {
  const { t } = useTranslation("common");

  return (
    <>
      {data.field_title && (
        <h2 className="text-3xl font-serif mb-8">{data.field_title}</h2>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {data.field_files.map((file) => {
          const splitted = file.filename.split(".");
          const extension = splitted.pop();
          const name = file.resourceIdObjMeta.description || splitted.join(".");
          const filesize = formatFileSize(file.filesize);

          return (
            <div
              key={file.id}
              className="py-4 px-8 bg-white border border-secondary border-opacity-50 shadow-sm"
            >
              <div className="mb-2 text-xl font-serif capitalize">{name}</div>

              <div className="text-secondary mb-6">
                <span className="uppercase">{extension}</span> - {filesize}
              </div>

              <div className="flex flex-col xl:flex-row gap-4 xl:gap-8">
                <Link
                  href={`${process.env.NEXT_PUBLIC_DRUPAL_RUNTIME_BASE_URL}${file.uri.url}`}
                  passHref
                >
                  <a
                    className="group flex items-center"
                    title={`Télécharger "${file.filename}" (${filesize})`}
                    download
                  >
                    <span className="inline-block p-3 bg-primary text-primary-darker rounded-full mr-2 group-hover:bg-primary-dark transition-colors">
                      <DownloadIcon className="h-4 w-4" />
                    </span>
                    <span className="underline underline-offset-4 decoration-transparent group-hover:decoration-primary transition-colors">
                      {t("utils.files.download")}
                    </span>
                  </a>
                </Link>

                <Link
                  href={`${process.env.NEXT_PUBLIC_DRUPAL_RUNTIME_BASE_URL}${file.uri.url}`}
                  passHref
                >
                  <a
                    className="group flex items-center"
                    title={`Ouvrir "${file.filename}" dans un nouvel onglet`}
                    target="blank"
                  >
                    <span className="inline-block p-3 bg-primary text-primary-darker rounded-full mr-2 group-hover:bg-primary-dark transition-colors">
                      <EyeIcon className="h-4 w-4" />
                    </span>
                    <span className="underline underline-offset-4 decoration-transparent group-hover:decoration-primary transition-colors">
                      {t("utils.files.see")}
                    </span>
                  </a>
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
