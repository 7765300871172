import React, { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { SelectorIcon, CheckIcon } from "@heroicons/react/outline";
import { Controller } from "react-hook-form";
import classNames from "lib/classNames";
import useField from "lib/useField";
import useTranslation from "next-translate/useTranslation";

export default function Select({ control, errors, field }) {
  const { t } = useTranslation("common");
  const { id, name, disabled, required, requiredMessage, options } =
    useField(field);
  const hasError = errors[name] || false;

  if (options === null) {
    return null;
  }

  return (
    <>
      <label
        htmlFor={id}
        className={classNames(
          hasError && "text-red-500",
          "inline-block text-lg mb-2"
        )}
      >
        {field["#title"]}
        {required && <span className="text-primary"> *</span>}
      </label>
      <Controller
        id={id}
        name={name}
        control={control}
        rules={{
          required: required && requiredMessage,
          disabled,
        }}
        render={({ field: { value, onChange } }) => (
          <>
            <Listbox value={value} onChange={onChange}>
              {({ open }) => (
                <div className="relative w-full">
                  <Listbox.Button
                    className={classNames(
                      hasError ? "border-red-500" : "border-secondary",
                      "group text-left w-full border p-4 border-opacity-50"
                    )}
                  >
                    <span
                      className={classNames(
                        value && value !== "" && "border-secondary",
                        "block truncate"
                      )}
                    >
                      {value &&
                      value !== "" &&
                      options.find((val) => val.value === value)
                        ? options.find((val) => val.value === value).key
                        : t("form.subject.placeholder")}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
                      <span className="inline-block p-2 bg-primary rounded-full group-hover:bg-primary-dark">
                        <SelectorIcon className="h-4 w-4 text-primary-darker" />
                      </span>
                    </span>
                  </Listbox.Button>
                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options
                      static
                      className="absolute z-10 mt-1 max-h-60 w-full overflow-auto border bg-white shadow-lg focus:outline-none"
                    >
                      {options.map((option) => (
                        <Listbox.Option
                          key={option.id}
                          className={({ active }) =>
                            classNames(
                              active && "bg-gray-100",
                              "relative cursor-default select-none p-4"
                            )
                          }
                          value={option.value}
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={classNames(
                                  selected ? "pl-6 font-medium" : "font-normal",
                                  "block truncate"
                                )}
                              >
                                {option.key}
                              </span>
                              {selected ? (
                                <span
                                  className="absolute inset-y-0 left-0 flex items-center pl-2.5"
                                  aria-hidden="true"
                                >
                                  <CheckIcon className="h-5 w-5" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              )}
            </Listbox>
            {errors[name] && (
              <p className="text-sm text-red-500">{errors[name].message}</p>
            )}
          </>
        )}
      />
    </>
  );
}
