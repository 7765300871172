
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import React from "react";
import { getPathsFromContext, getResourceFromContext } from "next-drupal";
import NodePageContent from "components/Page/NodePageContent";
import Layout from "components/Layout";
import { DrupalJsonApiParams } from "drupal-jsonapi-params";
import getCrumbs from "lib/getCrumbs";
import { getGlobals } from "lib/getGlobals";
import Header from "components/Header";
import Meta from "components/Meta";
import { getNodeChilds } from "lib/getMenuItem";
import NodePageMenuItem from "components/Page/NodePageMenuItem";
import getForm from "lib/getForm";
import Form from "components/Form";

export default function NodePage({ crumbs, globals, node, form }) {
  if (!node) return null;
  const childs = getNodeChilds(node.title, globals.menus.crumbsFallback);
  const isNotAlias = /(?:\/node\/)/;

  return (
    <Layout globals={globals}>
      <Meta
        title={node.field_meta_title || node.title}
        description={node.field_meta_description || null}
      />
      <Header
        crumbs={crumbs}
        title={node.title}
        notTranslated={node.field_not_translated}
      />
      <section className="py-10">
        <div className="container mx-auto px-4 lg:max-w-screen-lg">
          <NodePageContent node={node} />
        </div>
      </section>
      {form.id !== null && form.data !== null && (
        <section className="py-10">
          <div className="container mx-auto px-4 lg:max-w-screen-lg">
            <Form id={form.id} dataFields={form.data} />
          </div>
        </section>
      )}
      {childs.length > 0 && (
        <section className="py-10">
          <div className="container mx-auto px-4">
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8">
              {childs.map((item) => {
                // We assume that non translated pages has no alias
                if (item.url.match(isNotAlias) !== null) {
                  return null;
                }

                return (
                  <div key={item.id}>
                    <NodePageMenuItem item={item} />
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      )}
    </Layout>
  );
}

export async function getStaticPaths(context) {
  return {
    paths: await getPathsFromContext(["node--page"], context),
    fallback: true,
  };
}

 async function _getStaticProps(context) {
  const globals = await getGlobals(context);
  const params = new DrupalJsonApiParams();
  const node = await getResourceFromContext("node--page", context, {
    params: params
      .addInclude([
        "field_paragraphs",
        "field_paragraphs.field_image",
        "field_paragraphs.field_files",
        "field_paragraphs.field_paragraphs",
        "field_paragraphs.field_paragraphs.field_image",
        "field_form",
      ])
      .getQueryObject(),
  });

  if (!node || (!context.preview && !node?.status)) {
    return {
      notFound: true,
      revalidate: 30,
    };
  }

  const formId = node.field_form
    ? node.field_form.resourceIdObjMeta.drupal_internal__target_id
    : null;
  const formData = formId ? await getForm(formId) : null;

  return {
    props: {
      crumbs: getCrumbs(context, node, globals.menus.crumbsFallback),
      globals,
      node,
      form: {
        id: formId || null,
        data: formData || null,
      },
    },
    revalidate: 30,
  };
}


    export async function getStaticProps(ctx) {
        let res = _getStaticProps(ctx)
        if(typeof res.then === 'function') res = await res
        return {
          
          ...res,
          props: {
            ...(res.props || {}),
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/[...slug]',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  