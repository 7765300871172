import React from "react";
import { ArrowRightIcon } from "@heroicons/react/outline";
import Link from "next/link";
import { useRouter } from "next/router";

export default function NodePageMenuItem({ item }) {
  const router = useRouter();

  return (
    <div
      className="group relative flex gap-4 p-8 lg:p-10 bg-secondary-light cursor-pointer"
      onClick={() => router.push(item.url)}
    >
      <div className="flex-1 text-lg underline underline-offset-4 decoration-transparent transition-colors group-hover:decoration-primary">
        {item.title}
      </div>
      <div className="flex-initial">
        <Link href={item.url} prefetch={false} passHref>
          <a className="block p-2 bg-primary group-hover:bg-primary-dark rounded-full">
            <ArrowRightIcon className="h-4 w-4 text-primary-darker" />
            <span className="sr-only">Voir tout</span>
          </a>
        </Link>
      </div>
    </div>
  );
}
