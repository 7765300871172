export function getMenuItem(name, menu) {
  const item = menu.filter((newItem) => {
    return newItem.title === name;
  });

  return item.pop();
}

export function getMenuItemChilds(parent, menu) {
  return menu.filter((newItem) => {
    return newItem.parent === parent;
  });
}

export function getNodeChilds(name, menu) {
  const parent = getMenuItem(name, menu);
  return parent ? getMenuItemChilds(parent.id, menu) : [];
}
