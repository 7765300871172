import React from "react";
import Body from "components/Body";

export default function ParagraphText({ data }) {
  return data.field_highlighted ? (
    <ParagraphTextHighlighted data={data} />
  ) : (
    <>
      {data.field_title && (
        <h2 className="mb-8 text-3xl font-serif">{data.field_title}</h2>
      )}
      <div className="prose max-w-none">
        <Body value={data.field_text.processed} />
      </div>
    </>
  );
}

function ParagraphTextHighlighted({ data }) {
  return (
    <div className="bg-primary-light px-8 py-12 text-primary-darker">
      {data.field_title && <h2 className="mb-8 text-xl">{data.field_title}</h2>}
      <div className="prose max-w-none text-primary-darker">
        <Body value={data.field_text.processed} />
      </div>
    </div>
  );
}
