import React from "react";
import ParagraphText from "components/Paragraphs/ParagraphText";
import ParagraphImage from "components/Paragraphs/ParagraphImage";
import ParagraphDisclosure from "components/Paragraphs/ParagraphDisclosure";
import ParagraphCollapse from "components/Paragraphs/ParagraphCollapse";
import ParagraphGrid from "components/Paragraphs/ParagraphGrid";
import ParagraphCard from "components/Paragraphs/ParagraphCard";
import ParagraphFiles from "components/Paragraphs/ParagraphFiles";
import ParagraphVideo from "components/Paragraphs/ParagraphVideo";
import classNames from "lib/classNames";

export default function Paragraphs({ paragraphs, level = 0, ...props }) {
  const components = {
    "paragraph--text": ParagraphText,
    "paragraph--image": ParagraphImage,
    "paragraph--disclosure": ParagraphDisclosure,
    "paragraph--collapse": ParagraphCollapse,
    "paragraph--grid": ParagraphGrid,
    "paragraph--card": ParagraphCard,
    "paragraph--files": ParagraphFiles,
    "paragraph--video": ParagraphVideo,
  };

  return (
    <>
      {paragraphs.map((paragraph, index) => {
        if (Object.prototype.hasOwnProperty.call(components, paragraph.type)) {
          const ParagraphComponent = components[paragraph.type];

          return (
            <div
              key={paragraph.id}
              className={classNames(level === 0 && "mt-16")}
            >
              <ParagraphComponent
                data={paragraph}
                loopIndex={index}
                level={level}
                {...props}
              />
            </div>
          );
        }

        console.warn(
          `Unknown paragraph type "${paragraph.type}" at tree level ${level}.`
        );
        return null;
      })}
    </>
  );
}
