import classNames from "lib/classNames";
import useField from "lib/useField";
import React from "react";

export default function Textarea({ register, errors, field }) {
  const { id, name, disabled, required, requiredMessage } = useField(field);
  const hasError = errors[name] || false;

  return (
    <>
      <label
        htmlFor={id}
        className={classNames(
          hasError && "text-red-500",
          "inline-block text-lg mb-2"
        )}
      >
        {field["#title"]}
        {required && <span className="text-primary"> *</span>}
      </label>

      {hasError && (
        <p className="text-sm text-red-500 mb-2 font-semibold">
          {errors[name].message}
        </p>
      )}

      <textarea
        id={id}
        {...register(name, {
          required: required && requiredMessage,
          disabled,
        })}
        rows={field["#rows"] ? field["#rows"] : 5}
        placeholder={field["#placeholder"]}
        className={classNames(
          hasError ? "border-red-500" : "border-secondary",
          "w-full border p-4 placeholder:text-gray-300 border-opacity-50 "
        )}
      />
    </>
  );
}
